import {Box, Button, Paper} from '@mui/material';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import TutoringTable from './TutoringTable';
import TutoringDetails from './TutoringDetails';
import AddTutoringDialog from './AddTutoringDialog';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import DeleteDialog from './DeleteDialog';
import PortalSnackbar from '../../common/PortalSnackbar';
import {deleteData, getData, postData, putData} from '../../../DataAccessLayer';
import {
    courseMapping,
    courses,
    locations as locationService,
    tutoring
} from '../../../DataAccessLayer/services';
import getColumns from './columns';
import PortalAlert from '../../common/PortalAlert';
import ApiErrorMessage from '../../common/ApiErrorMessage';

class Tutoring extends Component {
    state = {
        columns: [],
        courses: [],
        distinctCourses: [],
        tutorings: [],

        isAddTutoring: false,
        isDetails: false,
        isDeleteTutoring: false,
        isLoading: false,

        selectedTutoring: {
            SUBJECT: '',
            COURSE_NUMBER: '',
            COURSE_TITLE: '',
            locations: []
        },
        snackbar: {
            isOpen: false,
            message: '',
            success: ''
        },

        locations: [],
        isError: false
    };

    componentDidMount() {
        this.loadData();
    }

    //θ(1) Loads all the necessary data for the page
    loadData = async () => {
        this.toggleIsLoading(true);
        this.loadTableHeaders();
        Promise.all([this.loadCourses(), this.loadCoursesMapping(), this.loadTutorings(), this.loadLocations()])
        .then(result => this.toggleIsError(false))
        .catch(err => {
            this.toggleIsError(true)
        })
        .finally(_ => this.toggleIsLoading(false))
    }

    loadCoursesMapping = () => {
        return new Promise((resolve, reject) => {
            getData(courseMapping, true)
            .then(data => {
                this.createDistinctCoursesList(data);
                resolve();
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    //θ(N) Where N is the number of courses
    //Creates an array of distinct subjects with COURSE_NUMBER & COURSE_TITLE as All
    createDistinctCoursesList = courses => {
        //Mapping of Subject: Subject Desc
        const distinctCourses = courses?.reduce((allCourses, currentCourse) => {
            allCourses[currentCourse.SUBJECT] = {
                SUBJECT: currentCourse.SUBJECT,
                COURSE_NUMBER: 'All',
                COURSE_TITLE: 'All',
                SUBJECT_DESC: currentCourse.SUBJECT_DESC
            }
            return allCourses;
        }, {});
        
        this.setState({
            distinctCourses
        })
    }

    //O(1) Fetches all Courses
    loadCourses = () => {
        return new Promise(async (resolve, reject) => {
            getData(courses, true)
            .then(data => {
                this.setState({
                    courses: data
                });
                resolve();
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
        });
    };

    toggleIsLoading(isLoading) {
        if (isLoading === undefined) isLoading = !this.state.isLoading;
        this.setState({
            isLoading: isLoading
        });
    }

    //O(1) Fetches all Tutorings
    loadTutorings = () => {
        return new Promise((resolve, reject) => {
            getData(tutoring, true).then(data => {
                this.setState({
                    tutorings: data
                });
                resolve();
            })
            .catch(err => {
                console.log(err);
                reject();
            });
        });
    }

    //O(N) where N is the number of column
    //Fetches all Columns from columns.js
    loadTableHeaders = () => {
        this.setState({
            columns: getColumns(this.setTutoringToDelete)
        });
    }

    loadLocations = () => {
        return new Promise((resolve, reject) => {
            getData(locationService.locations, true).then(data => {
                this.setState({
                    locations: data.map(item => ({
                        id: item.CATEGORY_ID + '_' + item.PLACE_ID,
                        text: item.TITLE,
                        url: item.URL,
                        mapUrl: item.MAP_URL
                    }))
                });
                resolve();
            })
            .catch(err => {
                console.log(err);
                reject();
            });
        });
    }

    toggleIsAddTutoring = () => {
        this.setState({
            isAddTutoring: !this.state.isAddTutoring
        });
    };

    toggleIsDetails = () => {
        this.setState({
            isDetails: !this.state.isDetails
        });
    };

    toggleIsError = (isError = !this.state.isError) => {
        this.setState({
            isError
        });
    };


    //O(1) Used to set Selected tutoring on row click
    onTutoringClick = event => {
        this.setState({
            selectedTutoring: event.row,
            isDetails: true
        });
    };

    //O(1) Updates the Locations selected in Dropdown
    onLocationsUpdate = event => {
        this.setState({
            selectedTutoring: {
                ...this.state.selectedTutoring,
                locations: event.target.value
            }
        });
    };

    //O(1) Updates the Tutoring
    updateTutoring = event => {
        this.toggleIsLoading(true);
        let selectedTutoring = this.state.selectedTutoring;
        delete selectedTutoring?.SUBJECT_DESC
        postData(tutoring, selectedTutoring, true)
            .then(result => {
                this.loadTutorings();
                this.setState(
                    {
                        snackbar: {
                            isOpen: true,
                            message: 'Course Tutoring Updated'
                        }
                    },
                    this.toggleIsDetails
                );
                this.toggleIsLoading(false);
            })
            .catch(err => console.log(err));
    };

    //O(1) Adds the Tutoring
    addTutoring = tutoringData => {
        delete tutoringData.SUBJECT_DESC
        this.toggleIsLoading(true);
        putData(tutoring, tutoringData)
            .then(result => {
                this.loadTutorings();
                this.setState(
                    {
                        snackbar: {
                            isOpen: true,
                            message: 'Course Tutoring Added'
                        }
                    },
                    this.toggleIsAddTutoring
                );
                this.toggleIsLoading(false);
            })
            .catch(err => console.log(err));
    };

    //O(1) Sets the tutoring to be deleted
    setTutoringToDelete = tutoring => {
        this.setState(
            {
                tutoringToDelete: tutoring
            },
            this.toggleIsDeleteTutoring
        );
    };

    toggleIsDeleteTutoring = () => {
        this.setState({
            isDeleteTutoring: !this.state.isDeleteTutoring
        });
    };

    //O(1) Deleted the Tutoring
    deleteTutoring = () => {
        this.toggleIsLoading(true);
        deleteData(tutoring + '/' + this.state.tutoringToDelete.COURSE)
            .then(result => {
                this.loadTutorings();
                this.setState(
                    {
                        tutoringToDelete: {},
                        snackbar: {
                            isOpen: true,
                            message: 'Course Tutoring Deleted'
                        }
                    },
                    this.toggleIsDeleteTutoring
                );
                this.toggleIsLoading(false);
            })
            .catch(err => console.log(err));
    };

    //O(1) Updates the Course Selected in Dropdown
    onCourseUpdate = selectedTutoring => {
        if (selectedTutoring) {
            this.setState({
                selectedTutoring: {
                    ...this.state.selectedTutoring,
                    SUBJECT: selectedTutoring.SUBJECT,
                    COURSE_NUMBER: selectedTutoring.COURSE_NUMBER,
                    COURSE_TITLE: selectedTutoring.COURSE_TITLE
                }
            });
        } else {
            this.setState({
                selectedTutoring: {
                    ...this.state.selectedTutoring,
                    SUBJECT: '',
                    COURSE_NUMBER: '',
                    COURSE_TITLE: ''
                }
            });
        }
    };

    onCloseSnackbar = (event, reason) => {
        this.setState({
            snackbar: {
                isOpen: false,
                message: ''
            }
        });
    };

    //O(1) Compare the courses
    compareCourses(course1, course2) {
        return (
            course1.SUBJECT === course2.SUBJECT &&
            course1.COURSE_NUMBER === course2.COURSE_NUMBER &&
            course1.COURSE_TITLE === course2.COURSE_TITLE
        );
    }

    //O(N) where N is the number of Tutorings
    //Checks if the course is already assigned to a tutoring
    disableOption = course => {
        let tutoringCourse = this.state.tutorings.find(tutoring =>
            this.compareCourses(tutoring, course)
        );
        if (!tutoringCourse) return false;

        return true;
    };

    render() {
        return (
            <React.Fragment>
                {
                    this.state.isError && !this.state.isLoading ?
                    <PortalAlert id={"tutoring__portalAlert"} severity="error" type="statusAlert">
                        <ApiErrorMessage
                            id={"tutoring__apiErrorMessage"}
                            reload={this.loadData}
                            widgetName={'Tutoring'}
                            isPage={true}
                        />
                    </PortalAlert>
                    :
                    <div id={"tutoring__div_tutoringCardWrapper"} className="myOdu__container_maxWidth" style={{marginTop: '2rem'}}>
                        <h3 id={"tutoring__text_tutoringTitle"}>Tutoring</h3>
                        {this.state.isAddTutoring && (
                            <AddTutoringDialog
                                id={"tutoring__dialog_addTutoring"}
                                locations={this.state.locations}
                                courses={this.state.courses}
                                addTutoring={this.addTutoring}
                                toggleIsAddTutoring={this.toggleIsAddTutoring}
                                disableOption={this.disableOption}
                                isLoading={this.state.isLoading}
                                compareCourses={this.compareCourses}
                                distinctCourses = {this.state.distinctCourses}
                            />
                        )}

                        {this.state.isDeleteTutoring && (
                            <DeleteDialog
                                id={"tutoring__dialog_deleteTutoring"}
                                title="Delete Tutoring Item"
                                toggleIsDeleteTutoring={this.toggleIsDeleteTutoring}
                                onSubmit={this.deleteTutoring}
                                tutoringToDelete={this.state.tutoringToDelete}
                                isLoading={this.state.isLoading}
                            />
                        )}
                        <PortalSnackbar
                            id={"tutoring__portalSnackbar"}
                            isOpen={this.state.snackbar.isOpen}
                            onClose={this.onCloseSnackbar}
                            message={this.state.snackbar.message}
                            success={this.state.snackbar.success}
                        />
                        <Button
                            variant="outlined"
                            id={"tutoring__button_addTutoring"}
                            sx={{mt: 1}}
                            onClick={this.toggleIsAddTutoring}
                        >
                            Add Tutoring
                        </Button>
                        <Box id={"tutoring__box_tutoringTable_wrapper"} display={{xs: 'none', xl: 'block'}}>
                            <Row id={"tutoring__row_tutoringTable_wrapper"}>
                                <Col id={"tutoring__col_tutoringTable_wrapper"}>
                                    <TutoringTable
                                        id="tutoring__tutoringTable_tutoringSummary"
                                        rows={this.state.tutorings}
                                        columns={this.state.columns}
                                        onClick={this.onTutoringClick}
                                        isLoading={this.state.isLoading}
                                    />
                                </Col>
                                <Col id={"tutoring__col_updateCourse_wrapper"}>
                                    <Paper
                                        id={"tutoring__paper_updateCourse_wrapper"}
                                        sx={{
                                            width: '100%',
                                            overflow: 'hidden',
                                            height: '100%',
                                            paddingX: 4,
                                            paddingY: 4
                                        }}
                                    >
                                        <TutoringDetails
                                            id="tutoring__tutoringDetails_update_large"
                                            selectedTutoring={
                                                this.state.selectedTutoring
                                            }
                                            locations={this.state.locations}
                                            courses={this.state.courses}
                                            isDetails={this.state.isDetails}
                                            isLoading={this.state.isLoading}
                                            onCourseUpdate={this.onCourseUpdate}
                                            onLocationsUpdate={this.onLocationsUpdate}
                                            onSubmit={this.updateTutoring}
                                            disableOption={this.disableOption}
                                            buttonText="Update Course"
                                            compareCourses={this.compareCourses}
                                            distinctCourses = {this.state.distinctCourses}
                                        />
                                    </Paper>
                                </Col>
                            </Row>
                        </Box>

                        <Box id={"tutoring__box_tutoringTableWrapper"} display={{s: 'block', xl: 'none'}}>
                            <Row id={"tutoring__row_tutoringTableWrapper"}>
                                {!this.state.isDetails && (
                                    <Col id={"tutoring__col_tutoringTableWrapper"}>
                                        <TutoringTable
                                            id="tutoring__tutoringtable_update"
                                            rows={this.state.tutorings}
                                            columns={this.state.columns}
                                            onClick={this.onTutoringClick}
                                            isLoading={this.state.isLoading}
                                        />
                                    </Col>
                                )}
                                {this.state.isDetails && (
                                    <Col id={"tutoring__col_contentWrapper"}>
                                        {this.state.isDetails && (
                                            <Button
                                                id={"tutoring__button_back"}
                                                variant="outlined"
                                                onClick={this.toggleIsDetails}
                                                sx={{marginTop: 1, marginBottom: 1}}
                                            >
                                                <FontAwesomeIcon id={"tutoring__icon_back"} icon={faArrowLeft} />
                                            </Button>
                                        )}
                                        <Paper
                                            id={"tutoring__paper_tutoringWrapper"}
                                            sx={{
                                                width: '100%',
                                                overflow: 'hidden',
                                                height: '100%',
                                                paddingX: 4,
                                                paddingY: 4
                                            }}
                                        >
                                            <TutoringDetails
                                                id="tutoring__tutoringDetails_update_small"
                                                selectedTutoring={
                                                    this.state.selectedTutoring
                                                }
                                                locations={this.state.locations}
                                                courses={this.state.courses}
                                                isDetails={this.state.isDetails}
                                                isLoading={this.state.isLoading}
                                                onCourseUpdate={this.onCourseUpdate}
                                                onLocationsUpdate={
                                                    this.onLocationsUpdate
                                                }
                                                onSubmit={this.updateTutoring}
                                                disableOption={this.disableOption}
                                                buttonText="Update Course"
                                                compareCourses={this.compareCourses}
                                                distinctCourses = {this.state.distinctCourses}
                                            />
                                        </Paper>
                                    </Col>
                                )}
                            </Row>
                        </Box>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default Tutoring;
