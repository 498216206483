import { Button, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";
import React from "react";
import { Col, Row } from "reactstrap";
import Box from '@mui/material/Box';
import PortalAutoComplete from "../../common/PortalAutocomplete";
import {cleanUpID} from "../../common/utils";
import { FormRadioButton } from "../../common/Form/FormRadioButton";
import { TUTORING_COURSE_TYPE } from "../../common/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const DdProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const boxStyle ={
    xs: 0.5,
    xxl:2 
}
class TutoringDetails extends React.Component {

    state = {
        courseType: ''
    }

    componentDidUpdate() {
        const selectedTutoring = this.props?.selectedTutoring
        //If the selected course has All as Course_Number then the course type needs to be updated to ALL
        if(selectedTutoring?.COURSE_NUMBER === TUTORING_COURSE_TYPE.ALL && this.state.courseType !== TUTORING_COURSE_TYPE.ALL) {
            this.setState({
                courseType: TUTORING_COURSE_TYPE.ALL
            })
        } else if(selectedTutoring?.COURSE_NUMBER && selectedTutoring?.COURSE_NUMBER !== TUTORING_COURSE_TYPE.ALL && this.state.courseType !== TUTORING_COURSE_TYPE.SPECIFIC) {
            //If the selected course has a valid Course_Number then the course type needs to be updated to SPECIFIC
            this.setState({
                courseType: TUTORING_COURSE_TYPE.SPECIFIC
            })
        } else if(!selectedTutoring?.COURSE_NUMBER && selectedTutoring.SUBJECT && (this.state.courseType === TUTORING_COURSE_TYPE.ALL || this.state.courseType === TUTORING_COURSE_TYPE.SPECIFIC)) {
            //Resets of Course Number not present
            this.setState({
                courseType: ''
            })
        }
    }

    //θ(1) Changes the selected course type radio
    onCourseType = (event) => {
        const courseType = event.target.value;
        this.props.onCourseUpdate({})
        this.setState({
            courseType
        })
    }

    render() {
        return <React.Fragment>
            
            <Typography id={this.props.id +"__text_tutoringDetailsTitle"} variant="h4" className="py-3">Tutoring Details</Typography>
            <Row id={ this.props.id +"__row_courseDetailsWrapper"}>
                <Row id={ this.props.id +"__col_courseDetailsWrapper"}>
                    <Col id={ this.props.id +"__col_courseWrapper"} xs={12} xxl={2}>
                        <Box id={this.props.id +"__box_courseWrapper"} pt={3} pb={boxStyle}>
                            <InputLabel className="myOdu__label" id={ this.props.id +"__course_select_label"} htmlFor="course-select">
                                Course(s)
                            </InputLabel>
                        </Box>
                    </Col>
                    <Col id = {this.props.id +"__col_course_select"}>
                        <Row>
                            {/* All Courses */}
                            <Box id = {this.props.id +"__box_course_select"} py={boxStyle}>
                                <Stack spacing={1} direction={{xs: 'column', md: 'row'}}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <FormRadioButton
                                            options={[
                                                {ariaLabel: 'Choose all courses', value: TUTORING_COURSE_TYPE.ALL, 
                                                checked: (this.state.courseType === TUTORING_COURSE_TYPE.ALL)}
                                            ]}
                                            labelGridProps = {{xs:0}}
                                            inputGridProps={{xs: 0, m:0, p:0}}
                                            onChange={this.onCourseType}
                                            disabled = {!this.props.isDetails}
                                        />
                                        <Typography sx={{whiteSpace: 'nowrap'}} variant='small'>All courses for</Typography>
                                    </Stack>
                                    <PortalAutoComplete
                                            id = {this.props.id +"__course_select_all"}
                                            options={Object.values(this.props.distinctCourses)}
                                            disableOption = {this.props.disableOption}
                                            value = {this.state.courseType === TUTORING_COURSE_TYPE.ALL ? this.props.selectedTutoring : ''}
                                            disabled = {!this.props.isDetails || this.state.courseType !== TUTORING_COURSE_TYPE.ALL}
                                            placeholder="Enter Course subject"
                                            getOptionDisabled = {(option) => {
                                                return this.props.disableOption(option) && !this.props.compareCourses(option, this.props.selectedTutoring)
                                            }}
                                            getOptionLabel={(option) => {
                                                if(!option.SUBJECT)
                                                    return '';
                                                return option.SUBJECT.concat(" - ", this.props?.distinctCourses?.[option.SUBJECT].SUBJECT_DESC)
                                            }}
                                            onChange = {(_, selectedValue) => {
                                                this.props.onCourseUpdate(selectedValue)
                                            }}
                                            ariaLabel = 'Tutoring Subjects'
                                        />
                                </Stack>
                            </Box>
                            {/* Specific Course */}
                            <Box id = {this.props.id +"__box_course_select"} py={boxStyle}>
                                <Stack spacing={1} direction={{xs: 'column', md: 'row'}}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <FormRadioButton
                                            options={[
                                                {ariaLabel: 'Choose specific course', value: TUTORING_COURSE_TYPE.SPECIFIC, 
                                                checked: (this.state.courseType === TUTORING_COURSE_TYPE.SPECIFIC)}
                                            ]}
                                            labelGridProps = {{xs:0}}
                                            inputGridProps={{xs: 0, m:0, p:0}}
                                            onChange={this.onCourseType}
                                            disabled = {!this.props.isDetails}
                                        />
                                        <Typography sx={{whiteSpace: 'nowrap'}} variant='small'>Specifically for</Typography>
                                    </Stack>
                                    <PortalAutoComplete
                                            id = {this.props.id +"__course_select"}
                                            options={this.props.courses}
                                            disableOption = {this.props.disableOption}
                                            value = {this.state.courseType === TUTORING_COURSE_TYPE.SPECIFIC ? this.props.selectedTutoring : {}}
                                            disabled = {!this.props.isDetails || this.state.courseType !== TUTORING_COURSE_TYPE.SPECIFIC}
                                            placeholder="Enter Course subject, number or title"
                                            getOptionDisabled = {(option) => {
                                                return this.props.disableOption(option) && !this.props.compareCourses(option, this.props.selectedTutoring)
                                            }}
                                            getOptionLabel={(option) => {
                                                if(!option.SUBJECT)
                                                    return '';
                                                return option.SUBJECT.concat(" ", option.COURSE_NUMBER, " ", option.COURSE_TITLE)
                                            }}
                                            onChange = {(_, selectedValue) => {
                                                this.props.onCourseUpdate(selectedValue)
                                            }}
                                            ariaLabel = 'Tutoring Courses'
                                        />
                                </Stack>
                            </Box>
                        </Row>
                    </Col>
                </Row>
                <Row id={this.props.id +"__box_formWrapper"}>
                    <Col id={this.props.id +"__col_locationsTitleWrapper"} xs={12} xxl={2}>
                        <Box id={this.props.id +"__box_text_locationsTitleWrapper"} pt={3} pb={boxStyle}>
                            <InputLabel className="myOdu__label" id={"location-select-label"} htmlFor="location-select-input">Locations</InputLabel>
                        </Box>
                    </Col>
                    <Col id={this.props.id +"__col_selectLocations_wrapper"}>
                        <Box id={this.props.id +"__box_selectLocations"} py={boxStyle}>
                            <Select
                                labelId="location-select-label" //for ADA
                                fullWidth size="small"
                                displayEmpty
                                id={this.props.id +"__dropdown_selectLocations"}
                                multiple
                                onChange={this.props.onLocationsUpdate}
                                MenuProps={DdProps}
                                value={this.props.selectedTutoring.locations}
                                disabled={!this.props.isDetails}
                                renderValue={(selected) => {
                                    if(!selected.length)
                                        return <span>Select location(s)</span>
                                    
                                    return selected.map((select) => select.text).join(', ')
                                }}
                                input={<OutlinedInput id={this.props.id +"__outlinedInput_selectLocations"} inputProps={{
                                    "aria-labelledby": "location-select-label"
                                }} />}
                                >
                                {this.props.locations.map((location) => (
                                    <MenuItem
                                        id={this.props.id +"__menuItem_"+cleanUpID(location.text)}
                                        key={location.text}
                                        value={location}
                                    >
                                    {location.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Col>
                </Row>
                <Row id={this.props.id +"__row_dialog_addCourseTutoring_wrapper"}>
                    <Col id={this.props.id +"__button_col_addCourseTutoring"} className="p-3">
                        <Box id={this.props.id +"__box_dialog_addCourseTutoring"} style={{float: 'right'}}>
                            <Button
                                id={this.props.id +"__button_dialog_addCourseTutoring"}
                                variant="outlined" sx={{mt:1}} 
                                onClick={this.props.onSubmit}
                                disabled={!this.props.isDetails || !this.props.selectedTutoring.locations.length || !this.props.selectedTutoring.SUBJECT || this.props.isLoading}>
                                {this.props.buttonText}
                            </Button>
                        </Box>
                    </Col>
                </Row>
            </Row>
        </React.Fragment>
    }
}


export default TutoringDetails;