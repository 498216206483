import {
    InputAdornment,
    TextField,
    Typography,
    Breadcrumbs,
    Link,
    Box
} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Container, Col, Row, Label} from 'reactstrap';
import {saveApplicationsFilter} from '../Landing/actions';
import ApplicationsList from '../common/ApplicationsList';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/pro-light-svg-icons';
import {BreakpointBoxes} from '../common/BreakpointBoxes';
import {isProduction, isUserAdmin} from '../Layout/utils';
class AllApps extends Component {
    state = {};

    onTextChange = e => {
        this.props.saveApplicationsFilter(e.target.value);
    };

    render() {
        const isAdmin = isUserAdmin(this.props.user);
        const isProd = isProduction();

        return (
            <React.Fragment>
                {isAdmin && !isProd && <BreakpointBoxes />}
                <div className="myOdu__allApps">
                    <Container
                        id="allApps__container"
                        className="myOdu__container secondaryPage"
                    >
                        <Typography component="h2">
                            All Applications List
                        </Typography>

                        <Breadcrumbs
                            className="myOdu__breadcrumbs"
                            id={'allApps__breadCrumbs_dashboard'}
                            aria-label="breadcrumb"
                        >
                            <Link
                                id={'allApps__breadCrumbs_linkDashboard'}
                                underline="hover"
                                href="/"
                            >
                                Dashboard
                            </Link>
                            <Typography id={'appApps__breadCrumbs_allApps'}>
                                All Applications
                            </Typography>
                        </Breadcrumbs>

                        <Box className="mx-3 mx-sm-0">
                            <Typography
                                component="h3"
                                className="search"
                                id="allApps__search"
                            >
                                Search for Applications
                            </Typography>

                            <TextField
                                onChange={this.onTextChange}
                                sx={{mb: 4}}
                                fullWidth
                                size="small"
                                aria-label="Search by keyword"
                                className="myOdu__noShowLabel"
                                placeholder="Search by keyword"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <FontAwesomeIcon
                                                icon={faMagnifyingGlass}
                                                size="lg"
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                inputProps={{
                                    'aria-label': 'Search by keyword'
                                }}
                            />

                            <ApplicationsList showHeart />
                        </Box>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        text: state.applicationsReducer.text,
        user: state.AWSReducer.user
    };
};

const mapDispatchToProps = dispatch => ({
    saveApplicationsFilter: text => dispatch(saveApplicationsFilter(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllApps);
