import {getStore, store} from '../store';

/**
 * Generates the impersonation header for API requests.
 *
 * This function retrieves the impersonation details from the Redux store and constructs
 * the impersonation header if the user is in impersonation mode. The header includes:
 * - `Impersonation`: The MIDAS of the user being impersonated.
 * - `ImpersonationRoles`: An array of role keys that are active (set to true) for the impersonated user.
 * - `ImpersonationGroups`: An array of group keys that are active (set to true) for the impersonated user.
 *
 * If the user is not in impersonation mode, an empty string is returned.
 *
 * @returns {object|string} The impersonation header object if impersonating, otherwise an empty string.
 */
export const getImpersonationHeader = () => {
    const impersonation =
        getStore().getState()?.impersonationReducer?.impersonation;
    return impersonation?.isImpersonating
        ? {
              Impersonation: impersonation.midas,
              ImpersonationRoles: Object.keys(impersonation.roles).filter(
                  key => impersonation.roles[key] === true
              ),
              ImpersonationGroups: Object.keys(impersonation.groups).filter(
                  key => impersonation.groups[key] === true
              )
          }
        : '';
};

export const getLtiToken = () => {
    return getStore().getState()?.AWSReducer?.user?.accessToken;
};

export const isLti = () => {
    return !!getStore().getState()?.AWSReducer?.user?.accessToken;
};

export const getBrowserMetadata = async () => {
    const uaData = navigator.userAgentData;

    return {
        userAgent: uaData
            ? await uaData.getHighEntropyValues([
                  'platform',
                  'platformVersion',
                  'architecture',
                  'model',
                  'uaFullVersion'
              ])
            : navigator.userAgent,
        language: navigator.language,
        cookiesEnabled: navigator.cookieEnabled,
        online: navigator.onLine,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio
    };
};
