import React, {Component} from 'react';
import {
    Tooltip,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Typography
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import MenuListItem from './MenuListItem';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import {faChevronDown, faChevronUp} from '@fortawesome/pro-light-svg-icons';

class ExpandableMenuListItem extends Component {
    state = {
        isMenuItemOpen: this.props.isMobile ? false : true
    };

    onClick = () => {
        this.setState({
            isMenuItemOpen: !this.state.isMenuItemOpen
        });
    };

    render() {
        return (
            <React.Fragment>
                {/* //TODO: make this generic and pass in a list of items */}

                <ListItem
                    onClick={this.onClick}
                    disableGutters
                    sx={{p: 0, pl: '4px', display: 'block'}}
                    className="expandedMenuItem"
                >
                    <ListItemButton
                        id={
                            'sideNav__button_' +
                            this.props.title.replace(' ', '')
                        }
                        onClick={this.props.onClick}
                        className={
                            this.props.isOpen
                                ? 'justify-content-start'
                                : 'justify-content-center'
                        }
                    >
                        {/* sidenav closed, show icon only */}
                        {!this.props.isSideNavOpen && (
                            <>
                                <Tooltip
                                    title={'Expand ' + this.props.title}
                                    placement="right"
                                    arrow
                                >
                                    <ListItemIcon
                                        sx={{
                                            color: '#DADADA',
                                            minWidth: '1.75rem',
                                            pt: 1,
                                            pb: 1
                                        }}
                                        role="button"
                                    >
                                        <FontAwesomeIcon
                                            icon={this.props.icon}
                                            size={'lg'}
                                            fixedWidth
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                            </>
                        )}

                        {/* sidenav open, show icon and text */}
                        {this.props.isSideNavOpen && (
                            <>
                                <ListItemIcon
                                    sx={{color: '#DADADA', minWidth: '1.75rem'}}
                                >
                                    <FontAwesomeIcon
                                        icon={this.props.icon}
                                        size={'lg'}
                                        fixedWidth
                                    />
                                </ListItemIcon>
                                {/* <Badge variant={this.props.variant} badgeContent={this.props.badgeCount} color='error' max={10} className={"myOdu__iconBadge " + this.props?.variant}> */}
                                <ListItemText
                                    disableTypography
                                    sx={{
                                        opacity: this.props.isSideNavOpen
                                            ? 1
                                            : 0
                                    }}
                                    primary={
                                        <Typography
                                            sx={{
                                                mr: 1,
                                                fontWeight: '600',
                                                color: 'white'
                                            }}
                                        >
                                            {this.props.title}
                                        </Typography>
                                    }
                                />
                                {/* </Badge> */}
                            </>
                        )}
                        {this.state.isMenuItemOpen ? (
                            <Tooltip
                                id="sideNav__icon_expandMenuItem"
                                title={'Expand ' + this.props.title}
                                placement="right"
                                arrow
                            >
                                <FontAwesomeIcon
                                    icon={faChevronUp}
                                    size={'xs'}
                                    fixedWidth
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip
                                id="sideNav__icon_collapseMenuItem"
                                title={'Collapse ' + this.props.title}
                                placement="right"
                                arrow
                            >
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size={'xs'}
                                    fixedWidth
                                />
                            </Tooltip>
                        )}
                    </ListItemButton>

                    <Collapse
                        in={this.state.isMenuItemOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List
                            sx={{ml: 0}}
                            component="ul"
                            disablePadding
                            className="expandedMenuList"
                        >
                            {this.props.expandableItems.map(item => {
                                return (
                                    (item.isVisible ?? true) && (
                                        <MenuListItem
                                            title={item.title}
                                            isOpen={this.props.isSideNavOpen}
                                            img={item.img}
                                            icon={item.icon}
                                            isNewWindow="true"
                                            onClick={() => {
                                                window.open(item.url, '_blank');
                                            }}
                                        />
                                    )
                                );
                            })}
                        </List>
                    </Collapse>
                </ListItem>
            </React.Fragment>
        );
    }
}

export default ExpandableMenuListItem;
