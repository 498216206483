import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import TutoringDetails from "./TutoringDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-light-svg-icons";
import PortalModal from "../../common/PortalModal";

class AddTutoringDialog extends Component {

    state = {
        newTutoring: {
            SUBJECT: '',
            COURSE_NUMBER: '',
            COURSE_TITLE: '',
            locations: []
        },
    };

    //O(1) Updates the course selected in dropdown
    onCourseUpdate = (selectedCourse) => {
        if(!selectedCourse) {
            selectedCourse = {
                ...this.state.newTutoring,
                SUBJECT: '',
                COURSE_NUMBER: '',
                COURSE_TITLE: '',
            }
        }
        this.setState({
            newTutoring: {
                ...this.state.newTutoring,
                SUBJECT: selectedCourse.SUBJECT,
                COURSE_NUMBER: selectedCourse.COURSE_NUMBER,
                COURSE_TITLE: selectedCourse.COURSE_TITLE,
                SUBJECT_DESC: selectedCourse.SUBJECT_DESC
            }
        });
    }

    //O(1) Updates the locations selected in dropdown
    onLocationsUpdate = event => {
        this.setState({
            newTutoring: {
                ...this.state.newTutoring,
                locations: event.target.value
            }
        })
    }

    //O(1) Adds the Tutoring
    onAddTutoring = event => {
        this.props.addTutoring(this.state.newTutoring);
    }

    render() {
        return <React.Fragment>
            <PortalModal
                onClose = {this.props.toggleIsAddTutoring}
                isOpen = {true}
                isCloseable={true}
                title = {'Add Course Tutoring'}
            >
                <TutoringDetails
                    id={this.props.id+"__tutoringDetails"}
                    selectedTutoring = {this.state.newTutoring}
                    locations = {this.props.locations}
                    courses = {this.props.courses}
                    onCourseUpdate = {this.onCourseUpdate}
                    onLocationsUpdate = {this.onLocationsUpdate}
                    onSubmit = {this.onAddTutoring}
                    disableOption = {this.props.disableOption}
                    isDetails
                    isLoading = {this.props.isLoading}
                    buttonText = 'Add Course'
                    compareCourses = {this.props.compareCourses}
                    distinctCourses = {this.props.distinctCourses}
                />
            </PortalModal>
        </React.Fragment>
    }

}

export default AddTutoringDialog;