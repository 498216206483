import React from 'react';
import {Controller} from 'react-hook-form';
import {
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography
} from '@mui/material';
import {FieldRequiredMark} from './FieldRequiredMark';

export const FormControlledRadioButton = ({
    control,
    label,
    name,
    options,
    rules
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field: {onChange, value}, formState: {errors}}) => (
                <FormRadioButton
                    label={label}
                    options={options}
                    value={value}
                    onChange={onChange}
                    required={rules}
                    error={!!_.get(errors, name)}
                    errorText={_.get(errors, name)?.message}
                />
            )}
        />
    );
};

export const FormRadioButton = ({
    label,
    error,
    helperText,
    errorText,
    required,
    options,
    value,
    onChange,
    labelGridProps = {
        xs: 0,
        md: 3,
        lg: 4,
        xl: 3
    },
    inputGridProps = {xs: 12, md: 9, lg: 8, xl: 9},
    disabled = false,
    ...props
}) => {
    return (
        <RadioGroup value={value} onChange={onChange} {...props}>
            <Grid container spacing={1}>
                {label && (
                    <Grid item {...labelGridProps} textAlign="end">
                        <Typography className="myOdu__label" component="label">
                            {label}{' '}
                            {required && <FieldRequiredMark label={label} />}
                        </Typography>
                    </Grid>
                )}

                <Grid item {...inputGridProps}>
                    {options.map((option, index) => (
                        <React.Fragment key={index}>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}>
                                <FormControlLabel
                                    sx={{ml: 0, mr:0}}
                                    value={option.value}
                                    control={<Radio size="small" />}
                                    label={option.label}
                                    aria-label={option.ariaLabel || option.label}
                                    checked={option.checked}
                                    disabled = {disabled}
                                />
                            </Grid>
                        </React.Fragment>
                    ))}

                    {error ? (
                        <Typography
                            display="flex"
                            justifyContent="flex-start"
                            variant="caption"
                            className="myOdu__error"
                        >
                            {errorText}
                        </Typography>
                    ) : (
                        <Typography
                            display="flex"
                            justifyContent="flex-start"
                            variant="caption"
                            className="myOdu__helper"
                        >
                            {helperText}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </RadioGroup>
    );
};